<template>
  <v-card>
    <div class="d-flex pa-4">
      <v-toolbar-title>學校列表</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="searchWords"
        placeholder="搜尋名稱"
        filled
        rounded
        dense
        append-icon="mdi-magnify"
        hide-details=""
      ></v-text-field>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            fab
            depressed
            class="ml-2"
            color="primary"
            @click="createTestAccounts"
            :disabled="loadingCreateTestAccounts || !filterSchools.length"
            v-bind="attrs"
            v-on="on"
            ><v-progress-circular
              v-if="loadingCreateTestAccounts"
              indeterminate
              size="20"
              width="2"
            />
            <v-icon v-else>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>一鍵建立測試帳號</span>
      </v-tooltip>
    </div>
    <v-list dense>
      <v-list-item-group>
        <v-list-item v-for="(item, index) in filterSchools" :key="index">
          <v-list-item-content>
            <router-link
              class="text-decoration-none"
              :to="`/admin/school/${item.id}/account`"
              >{{ item.name }} ({{ item.accountCount }})</router-link
            >
          </v-list-item-content>
          <div class="d-flex">
            <v-btn icon small class="mr-2" @click="toSchool(item)">
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
            <!-- <createSchoolAdminBtn
              :school-id="item.id"
              :district-id="item.districtId"
              @create="getSchools()"
            /> -->
          </div>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <!-- <AccountForm /> -->
  </v-card>
</template>

<script>
import School from '@/api/admin/School'
import Account from '@/api/Account'
// import AccountForm from '@/views/admin/school/account/AccountForm'

export default {
  name: undefined,
  mixins: [],
  components: {
    // AccountForm
  },
  props: {
    cityId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      allSchools: [],
      searchWords: '',
      loadingCreateTestAccounts: false
    }
  },
  computed: {
    schools() {
      return this.allSchools.filter((school) => school.cityId === this.cityId)
    },
    filterSchools() {
      return this.schools.filter((school) =>
        school.name.includes(this.searchWords)
      )
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {
    this.getSchools()
  },
  destroyed() {},
  methods: {
    getSchools() {
      School.list(this.cityId)
        .then((resp) => {
          this.allSchools = resp.data
        })
        .catch((error) => {
          console.log(error.response)
        })
    },
    toSchool(item) {
      this.$router.push({ name: 'SchoolOverview', params: { tagId: item.id } })
    },
    async createTestAccounts() {
      this.loadingCreateTestAccounts = true
      for (let i = 0; i < this.filterSchools.length; i++) {
        let school = this.filterSchools[i]
        let params = {
          email: school.serialNumber,
          password: `${school.serialNumber}123456`,
          name: `pjm_used`,
          roles: [
            {
              name: 'SCH_ADMIN',
              remarks: '學校管理者'
            }
          ],
          schoolId: school.id,
          districtId: school.districtId
        }
        try {
          await Account.create(params)
        } catch (error) {
          console.error(`建立 ${school.name} 測試帳號失敗`, error)
        }
      }
      this.loadingCreateTestAccounts = false
    }
  }
}
</script>

<style></style>
