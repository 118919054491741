<template>
  <div>
    <v-row>
      <v-col cols="auto">
        <v-btn
          block
          large
          class="py-6 px-8 mb-4 font-weight-bold"
          color="primary"
          @click="$router.push({ name: 'OperateRecord' })"
          >前往操作紀錄
          <v-icon small class="ml-2">mdi-arrow-right</v-icon>
        </v-btn>
        <RoleCard />
      </v-col>
      <v-col>
        <v-select
          v-model="theCityId"
          :items="citys"
          label="選擇縣市"
          hide-details
          class="mb-4"
          solo
          item-text="name"
          item-value="id"
          @change="changeCity()"
        ></v-select>
        <template v-if="theCityId">
          <CityAccountCard :city-id="theCityId" class="mb-4" />
          <SchoolCard :city-id="theCityId" />
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import City from '@/api/admin/City'
import RoleCard from './role/indexCard'
import CityAccountCard from './cityAccountCard'
import SchoolCard from './school/indexCard'

export default {
  name: undefined,
  mixins: [],
  components: {
    RoleCard,
    CityAccountCard,
    SchoolCard
  },
  props: {
    cityId: {
      type: String,
      required: false
    }
  },
  data: function () {
    return {
      theCityId: null,
      citys: []
    }
  },
  computed: {},
  watch: {
    cityId(val) {
      this.theCityId = val
    }
  },
  created: function () {},
  mounted: function () {
    if (this.cityId) this.theCityId = this.cityId
    this.getCity()
  },
  destroyed() {},
  methods: {
    getCity() {
      City.list()
        .then((resp) => {
          this.citys = resp.data
          if (this.citys.length === 1) this.theCityId = this.citys[0].id
        })
        .catch((error) => {
          console.log(error.response)
        })
    },
    changeCity() {
      if (this.theCityId) {
        this.$router.push(`/admin/city/${this.theCityId}`)
      }
    }
  }
}
</script>

<style></style>
