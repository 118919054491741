<template>
  <v-card>
    <v-card-title class="text-h5"> 新增權限 </v-card-title>

    <v-card-text>
      <v-text-field
        v-model="params.name"
        label="Name"
        hint="權限ID"
      ></v-text-field>
      <v-text-field
        v-model="params.remarks"
        label="Remarks"
        hint="學校帳號管理顯示名稱"
      ></v-text-field>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn color="grey" text @click="clear()"> 取消 </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="submit()"> 確定 </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import Roles from '@/api/Roles'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {},
  data: function () {
    return {
      params: {
        name: null,
        remarks: null
      }
    }
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {
    ...mapActions({
      getRoles: 'role/getSchoolRolesOption'
    }),
    clear() {
      this.$emit('close', true)
    },
    submit() {
      Roles.create(this.params)
        .then(() => {
          this.getRoles()
          this.$emit('close', true)
        })
        .catch(() => {
          alert('新增失敗')
        })
    }
  }
}
</script>

<style></style>
