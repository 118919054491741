<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small icon v-bind="attrs" v-on="on">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title> 新增縣市管理者帳號 </v-card-title>

      <v-card-text>
        <v-text-field
          v-model="params.userid"
          label="帳號"
          type="text"
          :rules="[(v) => !!v || '必填']"
        ></v-text-field>
        <v-text-field
          v-model="params.email"
          label="Email"
          type="email"
        ></v-text-field>
        <v-text-field
          v-model="params.password"
          label="密碼"
          type="text"
        ></v-text-field>
        <v-text-field
          v-model="params.name"
          label="名稱"
          type="text"
          :rules="[(v) => !!v || '必填']"
        ></v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="createAccount()"
          :disabled="!params.userid || !params.name"
        >
          新增
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Account from '@/api/Account'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    cityId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      dialog: false,
      params: {
        email: null
      }
    }
  },
  computed: {
    ...mapGetters({
      schoolRoles: 'role/schoolRolesOption'
    }),
    createParams() {
      return {
        userid: this.params.userid,
        email: this.params.email,
        password: this.params.password,
        name: this.params.name,
        roles: [
          {
            name: 'CITY_ADMIN',
            remarks: '縣市管理者'
          }
        ],
        cityId: this.cityId
      }
    },
    cityAdminRoleId() {
      const cityAdminRole = this.schoolRoles.find(
        (role) => role.name === 'CITY_ADMIN'
      )
      return cityAdminRole ? cityAdminRole.id : null
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {
    if (!this.schoolRoles || this.schoolRoles.legth === 0) {
      this.getRoles()
    }
  },
  destroyed() {},
  methods: {
    ...mapActions({
      getRoles: 'role/getSchoolRolesOption'
    }),
    createAccount() {
      Account.create(this.createParams)
        .then((resp) => {
          this.dialog = false
          this.resetParams()
          this.$emit('create', resp.data)
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            alert(error.response.data.message)
          } else {
            alert('新增失敗')
          }
        })
        .finally(() => {})
    },
    resetParams() {
      this.params.email = null
    }
  }
}
</script>

<style></style>
