<template>
  <v-card>
    <v-toolbar dense class="elevation-0">
      <v-toolbar-title>權限管理</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog v-model="createDialog" persistent width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" icon small v-bind="attrs" v-on="on">
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </template>
        <createCard @close="createDialog = false" />
      </v-dialog>
    </v-toolbar>
    <v-list dense>
      <v-list-item v-for="role in schoolRoles" :key="role.id">
        <v-btn text :to="`/admin/role/${role.name}/edit`"
          >{{ role.name }} {{ role.remarks }}</v-btn
        >
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import createCard from './createCard'

export default {
  name: undefined,
  mixins: [],
  components: {
    createCard
  },
  props: {},
  data: function () {
    return {
      createDialog: false
    }
  },
  computed: {
    ...mapGetters({
      schoolRoles: 'role/schoolRolesOption'
    })
  },
  watch: {},
  created: function () {},
  mounted: function () {
    this.getRoles()
  },
  destroyed() {},
  methods: {
    ...mapActions({
      getRoles: 'role/getSchoolRolesOption'
    })
  }
}
</script>

<style></style>
