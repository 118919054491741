<template>
  <v-card>
    <v-toolbar dense class="elevation-0">
      <v-toolbar-title class="mr-4">縣市帳號管理</v-toolbar-title>
      <createCityAdminBtn :city-id="cityId" @create="getAccounts()" />
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-list dense>
      <v-list-item v-for="account in accounts" :key="account.id" link>
        <v-list-item-content>
          <v-list-item-title
            v-text="account.name"
            class="text-body-2"
          ></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon @click="removeAccount(account.id)"> mdi-close </v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import Account from '@/api/Account'
import createCityAdminBtn from '@/views/admin/city/account/createCityAdminBtn'

export default {
  name: undefined,
  mixins: [],
  components: {
    createCityAdminBtn
  },
  props: {
    cityId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      allAccounts: []
    }
  },
  computed: {
    accounts() {
      return this.allAccounts.filter((account) => {
        return (
          account.roles &&
          account.roles.find((role) => role.name === 'CITY_ADMIN') &&
          account.cityId === this.cityId
        )
      })
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {
    this.getAccounts()
  },
  destroyed() {},
  methods: {
    getAccounts() {
      Account.list()
        .then((resp) => {
          this.allAccounts = resp.data
        })
        .catch((error) => {
          console.log(error.response)
        })
    },
    removeAccount(id) {
      Account.delete(id)
        .then(() => {
          this.getAccounts()
        })
        .catch((error) => {
          console.log(error.response)
        })
    }
  }
}
</script>

<style></style>
